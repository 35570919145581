import React from 'react';
import { graphql, Link } from 'gatsby';
import cx from 'classnames';
import { BlogTags } from '../components/learning-hub/blog-tags';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Share } from '../components/Share';
import { getTags } from '../utils/get-tags';
import { PageLayout } from '../components/layout';

import { MainContent } from '../components/layout/main-content';
import '../styles/article.scss';
import '../styles/share.scss';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  /**
   * DOM
   */
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { title, slug, category, type, readingTime, date, featuredImage } = frontmatter;
  const tags = getTags(category, type);
  const image = getImage(featuredImage);
  return (
    <PageLayout>
      <main>
        <MainContent cls="grid grid-cols-5 lg:grid-cols-14 gap-x-3 items-center hero py-16">
          <div className="col-start-1 col-span-full lg:col-start-3 lg:col-span-10 mb-6 ">
            <Link to="/insights">Insights</Link>
            <span className="opacity-70">&nbsp;/&nbsp; StableJournal</span>
          </div>
          <>
            <div className="col-start-1 col-span-full lg:col-start-3 lg:col-span-10 mb-6 ">
              <BlogTags tags={tags} />
            </div>
            <p
              className={cx(
                'col-start-1 col-span-full lg:col-start-3 lg:col-span-10 justify-center text-left ff-secondary text-primary mb-4 text-3xl md:text-4xl'
              )}
            >
              {title}
            </p>
            <ul className="col-start-1 col-span-full lg:col-start-2 lg:col-span-1 lg:mt-8 flex flex-row lg:flex-col gap-x-7 lg:gap-y-7 share-icons justify-start lg:items-end lg:self-start lg:pr-4 share-component ">
              <Share title={title} url={slug} />
            </ul>
            <div className="col-start-1 col-span-full lg:col-start-3 lg:col-span-10 lg:self-start mb-6 ">
              {(featuredImage && !!image) &&
                <GatsbyImage
                  image={image}
                  alt={`Featured image for blog: ${title}`}
                  className="rounded-4 w-full justify-self-start items-stretch mt-8"
                />
              }
              <p className="my-5 text-sh-gray-dark  divide-x divide-gray-400">
                <span className='pr-2'>Published</span>
                {date && <span className='pl-2'>{date}</span>}
              </p>
              <hr className="border-2 border-accent" />
            </div>

            <div
              className="col-start-1 col-span-full lg:col-start-3 lg:col-span-10 mb-6 article-body text-sh-gray-dark leading text-sh-b1s normal block"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </>
        </MainContent>
      </main>
    </PageLayout>
  );
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
          }
        }
        category
        type
        date
        readingTime
        hideArticle
      }
    }
  }
`;
