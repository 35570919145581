import React, { FC, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ShareIcon from 'common/dist/assets/icons/blog-share-icon.svg';
import TwitterIcon from 'common/dist/assets/icons/blog-twitter.svg';
import LinkedInIcon from 'common/dist/assets/icons/blog-linkedin.svg';
import FBIcon from 'common/dist/assets/icons/blog-fb.svg';
import { HANDLES, URLS } from '../constants';

interface Props {
  title: string;
  url: string;
}

export const Share: FC<Props> = ({ title, url }) => {
  const [value, setValue] = useState(url);
  const [copied, setCopied] = useState(false);

  const onClick = e => {
    e.currentTarget.classList.toggle('hidden-text');
  };

  const onCopy = () => {
    if (copied === false) {
      setCopied(true);
    }
  };

  return (
    <>
      <li className="share-link">
        <CopyToClipboard
          onCopy={onCopy}
          text={`${URLS.ROOT}${value}`}
          className="cursor-pointer"
          options={{ format: 'text/plain' }}
        >
          <ShareIcon />
        </CopyToClipboard>
        {copied ? (
          <span onClick={onClick} className="copied-text sh-shadow text-sh-b2">
            Link copied to clipboard.
          </span>
        ) : null}
      </li>
      <li>
        <a
          href={`https://twitter.com/intent/tweet/?text=${title}&url=${URLS.ROOT}${url}&via=${HANDLES.TWITTER}`}
        >
          <TwitterIcon />
        </a>
      </li>
      <li>
        <a
          href={`https://www.linkedin.com/shareArticle?url=${URLS.ROOT}${url}&title=${title}`}
        >
          <LinkedInIcon />
        </a>
      </li>
      <li>
        <a href={`https://www.facebook.com/sharer.php?u=${URLS.ROOT}${url}`}>
          <FBIcon />
        </a>
      </li>
    </>
  );
};
